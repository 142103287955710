import {createApi, retry} from '@reduxjs/toolkit/query/react';
import {baseQueryWithReauth} from './baseQueryWithReauth';
import {
    FunnelData,
    StatFilters,
    LeadReportData,
    LeadReportFilters,
    DailyStatData,
    TotalSessionsStat,
} from '../types';
import {UTM_LABELS} from '../../constants';

export type FunnelQuery = {
    id: string;
    filters: StatFilters;
};

export type DailyStatQuery = {
    id: string;
    filters: StatFilters;
};

export type LeadReportQuery = {
    filters: LeadReportFilters;
};

const getFilterString = (filters: StatFilters, quiz_id: string): string => {
    let urlParams = new URLSearchParams({id: quiz_id, debug: '1'});

    if (filters.dateRange?.from)
        urlParams.append('dateFrom', filters.dateRange.from);

    if (filters.dateRange?.to) urlParams.append('dateTo', filters.dateRange.to);

    UTM_LABELS.map(label => {
        if (filters[label]) {
            // @ts-ignore
            urlParams.append(label, filters[label]);
        }
    });

    return urlParams.toString();
};

export const statApi = createApi({
    reducerPath: 'statApi',
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        getFunnelData: builder.query<FunnelData, FunnelQuery>({
            query: ({id, filters}) =>
                'admin/stat/funnel?' + getFilterString(filters, id),
        }),
        getDailyStatData: builder.query<DailyStatData, DailyStatQuery>({
            query: ({id, filters}) =>
                'admin/stat/daily?' + getFilterString(filters, id),
        }),
        getTotalStatData: builder.query<TotalSessionsStat, string>({
            query: id => `admin/stat/total?id=${id}`,
        }),
        getLeadReportData: builder.query<LeadReportData, LeadReportQuery>({
            query: ({filters}) => {
                return `admin/stat/lead-report?dateFrom=${filters.dateRange.from}&dateTo=${filters.dateRange.to}&debug=1`;
            },
        }),
    }),
});

export const {
    useGetFunnelDataQuery,
    useGetLeadReportDataQuery,
    useGetDailyStatDataQuery,
    useGetTotalStatDataQuery,
} = statApi;
