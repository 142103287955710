import React, {useRef, useState} from 'react';
import {
    FunnelActionData,
    FunnelData,
    StatFilters,
    FunnelResultSessionsData,
    StatusEnum,
} from '../../../redux/types';
import {
    CallToActionOutlined,
    DynamicFormOutlined,
    EmojiEventsOutlined,
    LoginOutlined,
    QuestionMarkOutlined,
} from '@mui/icons-material';
import ExportButton from './ExportButton';
import {useAuth} from '../../../redux/hooks/useAuth';

type Props = {
    data?: FunnelData;
    filters: StatFilters;
};

export interface FilledActionsData extends FunnelActionData {
    shownType: string;
    icon: any;
    countPercent: number;
    exitsCount: number;
    exitsCountPercent: number;
    status: StatusEnum;
}

export interface FilledResultsData extends FunnelResultSessionsData {
    countPercent: number;
    exitsCount: number;
    exitsCountPercent: number;
}

const Table: React.FC<Props> = ({data, filters}) => {
    const [showDeleted, setShowDeleted] = useState(false);
    const tableRef = useRef(null);

    if (!data) return <div>Отсутствуют данные для отображения</div>;

    const actionIcon = (action: any) => {
        switch (action.type) {
            case 'answers_counts':
                return (
                    <QuestionMarkOutlined
                        fontSize="small"
                        style={{color: pageColor(action.type)}}
                        id={'action_icon_' + action.id}
                    />
                );
            case 'page_buttons_clicks':
                return (
                    <CallToActionOutlined
                        fontSize="small"
                        style={{color: pageColor(action.type)}}
                        id={'action_icon_' + action.id}
                    />
                );
            case 'form_submissions':
                return (
                    <DynamicFormOutlined
                        fontSize="small"
                        style={{color: pageColor(action.type)}}
                        id={'action_icon_' + action.id}
                    />
                );
            default:
                return '';
        }
    };

    const pageColor = (type: string) => {
        switch (type) {
            case 'answers_counts':
                return 'rgb(22 163 74)';
            case 'page_buttons_clicks':
                return 'rgb(202 138 4)';
            case 'form_submissions':
                return 'rgb(234 88 12)';
            case 'achieved_result':
                return 'rgb(79 70 229)';
            default:
                return 'black';
        }
    };

    const shownType = (action: any): string => {
        switch (action.type) {
            case 'answers_counts':
                return 'Ответы на вопросы';
            case 'page_buttons_clicks':
                return 'Клики на кнопки на страницах и в модальных окнах';
            case 'form_submissions':
                return 'Отправки форм';
            default:
                return '';
        }
    };

    const totalSessionsCount = data.sessions.total.reduce(
        (count, s) => count + s.count,
        0,
    );

    const abandonedSessionsCount = data.sessions.abandoned.count;

    const getFullActionsData = (): FilledActionsData[] => {
        const filledData: FilledActionsData[] = [];

        data.actions.map((action, index) => {
            const exitsCount =
                data.exit_points.find(ep => ep.id === action.id)?.count ?? 0;

            const countPercent =
                totalSessionsCount > 0
                    ? Math.round((action.count / totalSessionsCount) * 100)
                    : 0;

            const exitsCountPercent =
                totalSessionsCount > 0
                    ? Math.round((exitsCount / totalSessionsCount) * 100)
                    : 0;

            filledData.push({
                shownType: shownType(action),
                icon: actionIcon(action),
                countPercent: countPercent,
                exitsCount: exitsCount,
                exitsCountPercent: exitsCountPercent,
                ...action,
            });
        });
        return filledData;
    };

    const getFullResultsData = (): FilledResultsData[] => {
        const filledData: FilledResultsData[] = [];
        data.sessions.total.map(result => {
            if (result.result_id === null) return;

            const exitsCount =
                data.exit_points.find(ep => ep.id === result.result_id)
                    ?.count ?? 0;

            const countPercent =
                totalSessionsCount > 0
                    ? Math.round((result.count / totalSessionsCount) * 100)
                    : 0;

            const exitsCountPercent =
                totalSessionsCount > 0
                    ? Math.round((exitsCount / totalSessionsCount) * 100)
                    : 0;

            filledData.push({
                countPercent: countPercent,
                exitsCount: exitsCount,
                exitsCountPercent: exitsCountPercent,
                ...result,
            });
        });
        return filledData;
    };

    const fullActionsData = getFullActionsData();
    const fullResultsData = getFullResultsData();

    const totalResultsCount = fullResultsData.reduce(
        (count, s) => count + s.count,
        0,
    );

    const conversionPercent =
        totalSessionsCount > 0
            ? ((totalResultsCount / totalSessionsCount) * 100).toFixed(2)
            : 0;

    const availableDeletedActions = fullActionsData.find(
        a => a.status === 'deleted',
    );

    return (
        <div className="mt-5">
            <div className="flex flex-row">
                <div className="flex-1 text-sm">
                    Конверсия: {conversionPercent}%
                </div>
                <div className="flex-1">
                    <ExportButton
                        totalSessionsCount={totalSessionsCount}
                        abandonedSessionsCount={abandonedSessionsCount}
                        actionsData={fullActionsData}
                        resultsData={fullResultsData}
                        filters={filters}
                    />
                </div>
            </div>

            <table
                className="text-xs table-auto w-full border-t-gray-400"
                ref={tableRef}>
                <tbody className="text-sm divide-y divide-gray-100">
                    <tr>
                        <td
                            colSpan={2}
                            className="text-gray-400 bg-gray-50 rounded-sm uppercase text-center">
                            Сессии
                        </td>
                        <td className="text-gray-400 bg-gray-50 rounded-sm uppercase pr-3">
                            События
                        </td>
                        <td className="text-gray-400 bg-gray-50 rounded-sm uppercase">
                            Отказы
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <LoginOutlined fontSize="small" />
                        </td>
                        <td className="py-2">Всего</td>
                        <td className="py-2">
                            {totalSessionsCount}{' '}
                            <span className="text-[10px] text-gray-500">
                                / 100%
                            </span>
                        </td>
                        <td className="py-2">
                            {abandonedSessionsCount}{' '}
                            <span className="text-[10px] text-gray-500">
                                /{' '}
                                {totalSessionsCount
                                    ? Math.round(
                                          (abandonedSessionsCount /
                                              totalSessionsCount) *
                                              100,
                                      )
                                    : 0}
                                %
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td
                            colSpan={2}
                            className="text-gray-400 bg-gray-50 rounded-sm uppercase text-center">
                            Действия
                        </td>
                        <td
                            colSpan={2}
                            className="text-gray-400 bg-gray-50 rounded-sm uppercase text-center"
                        />
                    </tr>
                    {fullActionsData.map(action => (
                        <tr
                            key={action.id}
                            hidden={
                                action.status === 'deleted' && !showDeleted
                            }>
                            <td className="max-w-[25px] pr-1">
                                <div title={action.shownType}>
                                    {actionIcon(action)}
                                </div>
                            </td>
                            <td
                                className={
                                    'py-2 ' +
                                    (action.status === 'deleted'
                                        ? 'text-red-300'
                                        : '')
                                }
                                id={'title_' + action.id}>
                                {action.title.replace(/[\n\r]+/g, '')}
                            </td>
                            <td className="py-2">
                                {action.count}{' '}
                                <span className="text-[10px] text-gray-500">
                                    / {action.countPercent}%
                                </span>
                            </td>
                            <td>
                                {action.exitsCount}{' '}
                                <span className="text-[10px] text-gray-500">
                                    / {action.exitsCountPercent}%
                                </span>
                            </td>
                        </tr>
                    ))}
                    {availableDeletedActions && (
                        <tr>
                            <td colSpan={4} className="py-2 text-center">
                                <button
                                    className="btn btn-sm"
                                    onClick={() =>
                                        setShowDeleted(!showDeleted)
                                    }>
                                    {showDeleted ? 'Скрыть' : 'Показать'}{' '}
                                    удаленные этапы
                                </button>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td
                            colSpan={2}
                            className="text-gray-400 bg-gray-50 rounded-sm uppercase text-center">
                            Достижение результатов
                        </td>
                        <td
                            colSpan={2}
                            className="text-gray-400 bg-gray-50 rounded-sm uppercase text-center"
                        />
                    </tr>
                    {fullResultsData.map(
                        result =>
                            result.result_title && (
                                <tr key={result.result_id}>
                                    <td className="max-w-[25px] pr-1">
                                        <EmojiEventsOutlined
                                            fontSize="small"
                                            style={{
                                                color: pageColor(
                                                    'achieved_result',
                                                ),
                                            }}
                                            id={
                                                'action_icon_' +
                                                result.result_id
                                            }
                                        />
                                    </td>
                                    <td
                                        className="py-2"
                                        id={'title_' + result.result_id}>
                                        {result.result_title.replace(
                                            /[\n\r]+/g,
                                            '',
                                        )}
                                    </td>
                                    <td className="py-2">
                                        {result.count}{' '}
                                        <span className="text-[10px] text-gray-500">
                                            / {result.countPercent}%
                                        </span>
                                    </td>
                                    <td>
                                        {result.exitsCount}{' '}
                                        <span className="text-[10px] text-gray-500">
                                            / {result.exitsCountPercent}%
                                        </span>
                                    </td>
                                </tr>
                            ),
                    )}
                </tbody>
            </table>
        </div>
    );
};
Table.whyDidYouRender = true;
export default React.memo(Table);
