import React from 'react';
import {Quiz} from '../../../../redux/types';
import {Block, PowerSettingsNew} from '@mui/icons-material';
import OperationsButtons from '../OperationsButtons';
import {useAuth} from '../../../../redux/hooks/useAuth';

type Props = {
    quiz: Quiz;
};

const StatusWidget: React.FC<Props> = ({quiz}) => {
    const {user} = useAuth();

    return (
        <div className="w-full md:w-[300px] flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200 px-5 py-5 mb-5">
            <header className="flex justify-between items-start mb-2">
                <h2 className="flex-1 text-lg font-semibold text-gray-800">
                    Статус
                </h2>
                <div className="">
                    <OperationsButtons quiz={quiz} />
                </div>
            </header>

            {/* Статус*/}
            <div>
                {quiz.status === 'on' ? (
                    <div>
                        <PowerSettingsNew className="text-green-500" /> включен
                    </div>
                ) : (
                    <div>
                        <PowerSettingsNew className="text-red-500" /> выключен
                    </div>
                )}
            </div>

            {/* Если изменения запрещены модератором */}
            {quiz.prevent_changes && (
                <div className="text-sm mt-3">
                    <Block className="text-red-500" /> Администратор запретил
                    вносить изменения в квиз.
                </div>
            )}

            {/* Если не являешься владельцем квиза */}
            {quiz.owner.id !== user?.id && (
                <div className="mt-3 text-sm">
                    Владелец: {quiz.owner.username}
                </div>
            )}
        </div>
    );
};
export default StatusWidget;
