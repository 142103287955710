import React, {useRef} from 'react';
import {
    StatFilters,
    DailyStatData,
    DaySessionsStat,
    TotalSessionsStat,
} from '../../../redux/types';
import ExportButton from './ExportButton';

type Props = {
    data?: DailyStatData;
    filters: StatFilters;
};

export interface DailyStatWithConversion extends DaySessionsStat {
    conversion: string;
}

export interface TotalStatWithConversion extends TotalSessionsStat {
    conversion: string;
}

export type FilledDailyStat = {
    daily: DailyStatWithConversion[];
    total: TotalStatWithConversion;
};

const getFilledStat = (data: DailyStatData): FilledDailyStat => {
    let dailyStat: DailyStatWithConversion[] = [];
    let totalSessionsCount = 0;
    let totalResultsCount = 0;

    data.map(dayStat => {
        dailyStat.push({
            ...dayStat,
            date: new Date(dayStat.date).toLocaleDateString(),
            conversion:
                (dayStat.total > 0
                    ? (
                          (dayStat.achieved_results / dayStat.total) *
                          100
                      ).toFixed(2)
                    : 0) + '%',
        });
        totalSessionsCount += dayStat.total;
        totalResultsCount += dayStat.achieved_results;
    });

    return {
        daily: dailyStat,
        total: {
            total: totalSessionsCount,
            achieved_results: totalResultsCount,
            conversion:
                (totalSessionsCount > 0
                    ? ((totalResultsCount / totalSessionsCount) * 100).toFixed(
                          2,
                      )
                    : '0') + '%',
        },
    };
};

const Table: React.FC<Props> = ({data, filters}) => {
    const tableRef = useRef(null);

    if (!data) return <div>Отсутствуют данные для отображения</div>;
    const filledStat = getFilledStat(data);

    return (
        <div className="mt-5">
            <div className="flex flex-row">
                {!filters.dateRange.from && (
                    <div className="flex-1 text-sm">
                        Показана статистика за последние 7 дней
                    </div>
                )}
                <div className="flex-1">
                    <ExportButton filledStat={filledStat} filters={filters} />
                </div>
            </div>

            <table
                className="text-xs table-auto w-full border-t-gray-400"
                ref={tableRef}>
                <tbody className="divide-y divide-gray-100">
                    <tr className="text-gray-400 bg-gray-50 uppercase rounded-sm">
                        <td className="pl-2">Дата</td>
                        <td className="">Сессии</td>
                        <td className="">Лиды</td>
                        <td className="pr-2">Конверсия</td>
                    </tr>

                    {filledStat.daily.map(dayData => (
                        <tr key={dayData.date} className="text-xs">
                            <td className="py-2 pl-2">{dayData.date}</td>
                            <td className="py-2">{dayData.total}</td>
                            <td className="py-2">{dayData.achieved_results}</td>
                            <td className="py-2 pr-2">{dayData.conversion}</td>
                        </tr>
                    ))}
                    <tr className="text-xs font-bold">
                        <td className="py-2 pl-2">ИТОГО</td>
                        <td className="py-2">{filledStat.total.total}</td>
                        <td className="py-2">
                            {filledStat.total.achieved_results}
                        </td>
                        <td className="py-2 pr-2">
                            {filledStat.total.conversion}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
Table.whyDidYouRender = true;
export default React.memo(Table);
