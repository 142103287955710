import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/dist/query';
import {quizApi} from '../services/quizApi';
import {authReducer} from '../slices/authSlice';
import {authApi} from '../services/authApi';
import storage from 'redux-persist/lib/storage';
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    PURGE,
    REGISTER,
    REHYDRATE,
} from 'redux-persist';
import {googleAuthApi} from '../services/googleAuthApi';
import {statApi} from '../services/statApi';
import {userApi} from '../services/userApi';
import {errorLogger} from '../middlewares/errorLogger';
import {statFiltersReducer} from '../slices/statFilterSlice';

const reducers = combineReducers({
    [quizApi.reducerPath]: quizApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [googleAuthApi.reducerPath]: googleAuthApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [statApi.reducerPath]: statApi.reducer,
    auth: authReducer,
    statFilters: statFiltersReducer,
});

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,

    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        })
            .concat(quizApi.middleware)
            .concat(userApi.middleware)
            .concat(statApi.middleware)
            .concat(googleAuthApi.middleware)
            .concat(authApi.middleware)
            .concat(errorLogger),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
