import React from 'react';
import CsvDownloader from 'react-csv-downloader';
import {FileDownloadOutlined} from '@mui/icons-material';
import DropdownButtonMenu from '../../../components/Elements/DropdownButtonMenu';
import {FilledDailyStat} from './Table';
import {StatFilters} from '../../../redux/types';

type Props = {
    filledStat: FilledDailyStat;
    filters: StatFilters;
};

const ExportButton: React.FC<Props> = ({filledStat, filters}) => {
    const CSVColumns = [
        {
            id: '1',
            displayName: 'Дата',
        },
        {
            id: '2',
            displayName: 'Сессии',
        },
        {
            id: '3',
            displayName: 'Лиды',
        },
        {
            id: '4',
            displayName: 'Конаврсия',
        },
    ];

    const getCSVData = () => {
        const data = [
            // {
            //     '1': 'Всего сессий',
            //     '2': String(totalSessionsCount),
            //     '3': '100%',
            //     '4': String(abandonedSessionsCount),
            //     '5':
            //         (totalSessionsCount
            //             ? Math.round(
            //                   (abandonedSessionsCount / totalSessionsCount) *
            //                       100,
            //               )
            //             : 0) + '%',
            // },
        ];
        // data.push({
        //     '1': 'Этапы',
        //     '2': '',
        //     '3': '',
        //     '4': '',
        //     '5': '',
        // });
        filledStat.daily.map(dayStat =>
            data.push({
                '1': dayStat.date,
                '2': String(dayStat.total),
                '3': String(dayStat.achieved_results),
                '4': dayStat.conversion,
            }),
        );

        data.push({
            '1': 'ИТОГО',
            '2': String(filledStat.total.total),
            '3': String(filledStat.total.achieved_results),
            '4': filledStat.total.conversion,
        });

        return data;
    };

    const getFileName = () => {
        let name = 'dayStat';
        if (filledStat.daily.length > 0) {
            name += '_' + filledStat.daily[0].date;
            if (filledStat.daily.length > 1) {
                name +=
                    '-' + filledStat.daily[filledStat.daily.length - 1].date;
            }
        }
        return name;
    };

    return (
        <div className="mb-1 text-right">
            <DropdownButtonMenu
                icon={<FileDownloadOutlined />}
                className="relative inline-flex"
                align="right"
                title="Загрузить таблицу">
                <li>
                    <CsvDownloader
                        filename={getFileName()}
                        extension=".csv"
                        separator=";"
                        // wrapColumnChar=""
                        columns={CSVColumns}
                        datas={getCSVData()}>
                        <button className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                            CSV
                        </button>
                    </CsvDownloader>
                </li>
            </DropdownButtonMenu>
        </div>
    );
};
ExportButton.whyDidYouRender = true;
export default React.memo(ExportButton);
