import React from 'react';
import {OpenInNewOutlined} from '@mui/icons-material';
import {Quiz} from '../../../redux/types';
import {useAuth} from '../../../redux/hooks/useAuth';
import {getQuizLink} from '../../../utils';
type Props = {
    quiz: Quiz;
};
const GoToQuizLink: React.FC<Props> = ({quiz}) => {
    const {user} = useAuth();

    const quizLink = () => {
        // @ts-ignore
        const link = getQuizLink(quiz, user);

        return link ? (
            <a href={link} target="_blank" title="Открыть квиз">
                <OpenInNewOutlined className="hover:text-gray-600" />
            </a>
        ) : null;
    };

    return quizLink();
};

export default GoToQuizLink;
