import {ANSWER_TYPES} from '../constants';
import {
    Form,
    Page,
    PageInfo,
    Question,
    Quiz,
    Result,
    User,
} from '../redux/types';

export const titleByType = (type: string) => {
    return ANSWER_TYPES.find(opt => opt.id === type)?.title;
};

export const pageInfoByElementId = (
    questions: Question[],
    forms: Form[],
    pages: Page[],
    results: Result[],
    id: string,
): PageInfo | undefined => {
    const question = questions.find(e => e.id === id);
    if (question) return {type: 'question', obj: question};

    const form = forms.find(e => e.id === id);
    if (form) return {type: 'form', obj: form};

    const result = results.find(e => e.id === id);
    if (result) return {type: 'result', obj: result};

    const page = pages.find(e => e.id === id);
    if (page) return {type: 'page', obj: page};

    return undefined;
};

export const allScopesGranted = (
    grantedScopes: string,
    needScopes: string[],
): boolean => {
    let isOk = true;
    needScopes.map(search => {
        if (!grantedScopes.includes(search)) isOk = false;
    });
    return isOk;
};

export const getQuizLink = (quiz: Quiz, user: User): string | null => {
    let link = null;
    const urlParams = '?utm_source=panel&utm_content=user:' + user?.username;
    if (quiz.domain) {
        link = 'https://' + quiz.domain + urlParams;
    } else if (quiz.url_id) {
        link =
            process.env.REACT_APP_MAIN_QUIZ_URL + '/' + quiz.url_id + urlParams;
    }
    return link;
};
