import React from 'react';
import {Quiz} from '../../../../redux/types';
import {
    useCloneQuizMutation,
    useDeleteSessionsMutation,
    useUpdateQuizMutation,
} from '../../../../redux/services/quizApi';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {FileDownloadOutlined, MoreVert} from '@mui/icons-material';
import DropdownButtonMenu from '../../../../components/Elements/DropdownButtonMenu';

type Props = {
    quiz: Quiz;
};

const OperationsButtons: React.FC<Props> = ({quiz}) => {
    const [deleteSessions] = useDeleteSessionsMutation();
    const [updateQuiz] = useUpdateQuizMutation();
    const [cloneQuiz] = useCloneQuizMutation();
    const navigate = useNavigate();

    const onPressDeleteSessions = () => {
        // e.stopPropagation();
        if (!window.confirm('Удалить безвозвратно все прохождения квиза?'))
            return;
        deleteSessions(quiz)
            .unwrap()
            .then(result => {
                toast.success(`Удалено ${result} сессий`);
            });
    };

    const onPressDeleteQuiz = async () => {
        if (!window.confirm('Удалить квиз?')) return;

        await updateQuiz({id: quiz.id, status: 'deleted'})
            .unwrap()
            .then(() => {
                toast.success('Квиз удален.');
                navigate('/');
            })
            .catch(() => {
                toast.error('Извините, не получилось :(');
            });
    };

    const onPressClone = async () => {
        await cloneQuiz({id: quiz.id, status: 'deleted'})
            .unwrap()
            .then(result => {
                toast.success(`Создан клон квиза`);
                navigate('/editQuiz/' + result + '#settings');
            })
            .catch(() => {
                toast.error('Извините, не получилось :(');
            });
    };

    return (
        <DropdownButtonMenu
            icon={<MoreVert />}
            className="relative inline-flex"
            align="right"
            title="Действия">
            <button
                className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                onClick={onPressClone}>
                Клонировать
            </button>

            <hr />

            <button
                className="font-medium text-sm text-red-600 hover:text-red-800 flex py-1 px-3 text-left"
                onClick={onPressDeleteSessions}>
                Удалить данные о прохождениях
            </button>

            <button
                className="font-medium text-sm text-red-600 hover:text-red-800 flex py-1 px-3"
                onClick={onPressDeleteQuiz}>
                Удалить квиз
            </button>
        </DropdownButtonMenu>
    );
};
export default OperationsButtons;
