import React, {useEffect} from 'react';
import DateRangePicker from '../../../components/Elements/DateRangePicker';
import {useForm, Controller} from 'react-hook-form';
import {LeadReportFilters} from '../../../redux/types';
import {Check} from '@mui/icons-material';

type Props = {
    onSubmit: (data: LeadReportFilters) => void;
};
const Filter: React.FC<Props> = ({onSubmit}) => {
    const form = useForm<LeadReportFilters>();
    const {control, handleSubmit, watch} = form;

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleSubmit(data => onSubmit(data))(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    const dateWatch = watch('dateRange');

    React.useEffect(() => {
        if (dateWatch && dateWatch.from && dateWatch.to) {
            handleSubmit(onSubmit)();
        }
    }, [dateWatch]);

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-row">
                    <div className="mr-1">
                        <Controller
                            control={control}
                            name="dateRange"
                            render={({
                                field: {onChange, onBlur, value, ref},
                            }) => <DateRangePicker onChange={onChange} />}
                        />
                    </div>
                    <div className="mr-1 ">
                        <button type="submit">
                            <Check className="hover:text-indigo-500" />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Filter;
