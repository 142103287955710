import React, {useEffect} from 'react';
import DateRangePicker from '../Elements/DateRangePicker';
import {useForm, Controller} from 'react-hook-form';
import {StatFilters} from '../../redux/types';
import {Check, Close} from '@mui/icons-material';
import {useSelector} from 'react-redux';
import {
    selectStatFilters,
    setFilters,
} from '../../redux/slices/statFilterSlice';
import {useAppDispatch} from '../../redux/hooks';

const StatFilter: React.FC = () => {
    const dispatch = useAppDispatch();
    const filters = useSelector(selectStatFilters);
    const form = useForm<StatFilters>({defaultValues: filters});
    const {control, register, handleSubmit, watch, reset} = form;
    const nullValues: StatFilters = {
        dateRange: {from: undefined, to: undefined},
    };

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleSubmit(data => onSubmit(data))(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    const dateWatch = watch('dateRange');

    React.useEffect(() => {
        if (dateWatch && dateWatch.from && dateWatch.to) {
            handleSubmit(onSubmit)();
        }
    }, [dateWatch]);

    const onSubmit = (filters: StatFilters) => {
        dispatch(setFilters(filters));
    };

    return (
        <div>
            <form
                onSubmit={handleSubmit(onSubmit)}
                onReset={() => {
                    reset(nullValues);
                    onSubmit(nullValues);
                }}>
                <div className="flex flex-row">
                    <div className="mr-1">
                        <Controller
                            control={control}
                            name="dateRange"
                            render={({field: {onChange, onBlur, value}}) => (
                                <DateRangePicker
                                    onChange={onChange}
                                    defaultFrom={value.from}
                                    defaultTo={value.to}
                                />
                            )}
                        />
                    </div>
                    <div className="mr-1 hidden sm:block">
                        <input
                            id="placeholder"
                            className="form-input w-full"
                            type="text"
                            placeholder="utm_source"
                            {...register('utm_source')}
                        />
                    </div>
                    <div className="mr-1 hidden sm:block">
                        <input
                            id="placeholder"
                            className="form-input w-full"
                            type="text"
                            placeholder="utm_medium"
                            {...register('utm_medium')}
                        />
                    </div>
                    <div className="mr-1 hidden sm:block">
                        <input
                            id="placeholder"
                            className="form-input w-full"
                            type="text"
                            placeholder="utm_campaign"
                            {...register('utm_campaign')}
                        />
                    </div>
                    <div className="mr-1 hidden sm:block">
                        <input
                            id="placeholder"
                            className="form-input w-full"
                            type="text"
                            placeholder="utm_content"
                            {...register('utm_content')}
                        />
                    </div>
                    <div className="mr-1 hidden sm:block">
                        <input
                            id="placeholder"
                            className="form-input w-full"
                            type="text"
                            placeholder="utm_term"
                            {...register('utm_term')}
                        />
                    </div>
                    <div className="mr-1 ">
                        <button type="submit">
                            <Check className="hover:text-indigo-500" />
                        </button>
                    </div>
                    <div className="mr-1 ">
                        <button type="reset">
                            <Close className="hover:text-indigo-500" />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default StatFilter;
