import React, {useEffect} from 'react';
import Icicle from 'icicle-chart';
import {FunnelData} from '../../redux/types';

type Props = {
    data?: FunnelData;
};

const prepareLabel = (label: string) => {
    const wordsLimit = 3;
    const parts = label.split(' ');
    let ret = parts.slice(0, 3).join('\n');
    if (parts.length > wordsLimit) {
        return ret + '...';
    } else return ret;
};

const Chart: React.FC<Props> = ({data}) => {
    const myChart = Icicle();

    useEffect(() => {
        const block = document.getElementById('funnel-chart');
        if (block && !myChart.data()) {
            const dataArr = {
                name: 'Источник',
                children: JSON.parse(JSON.stringify(data?.tracks)),
            };

            const totalSessionsCount = data?.sessions.total.reduce(
                (count, s) => count + s.count,
                0,
            );

            myChart
                .orientation('td')
                .height(150)
                .width(block.offsetWidth)
                .size('count')
                .color(node => {
                    const sourceBlock = document.getElementById(
                        'action_icon_' + node.name,
                    );
                    return prepareLabel(sourceBlock?.style.color ?? 'black');
                })
                .label(node => {
                    const sourceBlock = document.getElementById(
                        'title_' + node.name,
                    );
                    return prepareLabel(sourceBlock?.innerHTML ?? '');
                })
                .tooltipContent((d, node) => {
                    const percent = totalSessionsCount
                        ? Math.round((node.value / totalSessionsCount) * 100)
                        : 0;
                    return `Достигли: <i>${node.value}  (${percent}%)</i>`;
                })
                .excludeRoot(true)
                .data(dataArr)(block);
        }
    }, [data?.tracks.length]);

    if (!data?.tracks || data?.tracks.length === 0) return null;

    return (
        <div className="mt-2">
            {/*<div className="border-t-8 border-l-2 border-white z-10 rounded relative h-[10px] w-full " />*/}
            <div className="" id="funnel-chart" />
        </div>
    );
};

Chart.whyDidYouRender = true;
export default React.memo(Chart);
