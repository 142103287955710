import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetQuizQuery} from '../../redux/services/quizApi';
import {useGetDailyStatDataQuery} from '../../redux/services/statApi';
import ActivityIndicator from '../../components/ActivityIndicator';
import Table from './Table';
import Breadcrumbs from '../../components/Elements/Breadcrumbs';
import StatFilter from '../../components/StatFilter';
import DayStatChart from '../../components/DayStatChart';
import {FilterAltOutlined} from '@mui/icons-material';
import {useSelector} from 'react-redux';
import {selectStatFilters} from '../../redux/slices/statFilterSlice';

function DailyStatPage() {
    const navigate = useNavigate();
    const filters = useSelector(selectStatFilters);
    const {quizId} = useParams<{quizId: string}>();

    const {
        data: quiz,
        isFetching: isQuizFetching,
        isLoading: isQuizLoading,
        // @ts-ignore
    } = useGetQuizQuery(quizId);

    const {
        data: dailyStatData,
        isFetching: isStatFetching,
        isLoading: isStatLoading,
    } = useGetDailyStatDataQuery(
        {
            // @ts-ignore
            id: quizId,
            filters: filters,
        },
        {refetchOnMountOrArgChange: true},
    );

    if (!quiz) return null;

    return (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                    <Breadcrumbs
                        breadcrumbs={[
                            {title: 'Квизы', url: '/'},
                            {title: quiz.title, url: '/quiz/' + quiz.id},
                            {title: 'Статистика по дням'},
                        ]}
                    />
                </div>

                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    {/* Start */}
                    <button
                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                        onClick={() => navigate('/funnel/' + quiz.id)}>
                        <FilterAltOutlined
                            className="w-4 h-4 fill-current opacity-50 shrink-0"
                            fontSize="small"
                        />
                        <span className="xs:block ml-2">Воронка</span>
                    </button>
                </div>
            </div>
            <StatFilter />

            {isQuizLoading ||
            isStatFetching ||
            isStatLoading ||
            isQuizFetching ? (
                <ActivityIndicator />
            ) : (
                <>
                    {(!dailyStatData || dailyStatData.length > 1) && (
                        <div className="h-[150px]">
                            <DayStatChart data={dailyStatData} />
                        </div>
                    )}
                    <Table filters={filters} data={dailyStatData} />
                </>
            )}
        </div>
    );
}

export default DailyStatPage;
