import React from 'react';
import {Quiz} from '../../../redux/types';
import {OpenInNewOutlined, Settings} from '@mui/icons-material';
import Breadcrumbs from '../../../components/Elements/Breadcrumbs';
import {useNavigate} from 'react-router-dom';
import StatWidget from './StatWidget';
import StatusWidget from './StatusWidget';
import {getQuizLink} from '../../../utils';
import {useAuth} from '../../../redux/hooks/useAuth';

type Props = {
    quiz: Quiz;
};

const Dashboard: React.FC<Props> = ({quiz}) => {
    const navigate = useNavigate();
    const {user} = useAuth();

    // @ts-ignore
    const quizLink = getQuizLink(quiz, user);

    return (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}

            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                    <Breadcrumbs
                        breadcrumbs={[
                            {title: 'Квизы', url: '/'},
                            {title: quiz.title},
                        ]}
                    />
                </div>

                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    {/* Start */}
                    {quizLink && (
                        <a
                            className="btn border-gray-200 hover:border-gray-300 text-indigo-500"
                            target="_blank"
                            href={quizLink}>
                            <OpenInNewOutlined
                                className="w-4 h-4 fill-current opacity-50 shrink-0"
                                fontSize="small"
                            />
                            <span className="xs:block ml-2">Открыть квиз</span>
                        </a>
                    )}
                    <button
                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                        onClick={() => navigate('/editQuiz/' + quiz.id)}>
                        <Settings
                            className="w-4 h-4 fill-current opacity-50 shrink-0"
                            fontSize="small"
                        />
                        <span className="xs:block ml-2">Настроить</span>
                    </button>
                </div>
            </div>

            <div>
                <div className="flex flex-wrap">
                    <StatusWidget quiz={quiz} />
                    <StatWidget quiz={quiz} />
                </div>
            </div>
        </div>
    );
};

Dashboard.whyDidYouRender = true;
export default React.memo(Dashboard);
