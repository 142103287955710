import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {StatFilters} from '../types';
import {RootState} from '../store';

const slice = createSlice({
    name: 'statFilters',
    initialState: {dateRange: {from: undefined, to: undefined}} as StatFilters,
    reducers: {
        setFilters: (state, action: PayloadAction<StatFilters>) => {
            return action.payload;
        },
        unSetFilter: state => {
            return {dateRange: {from: undefined, to: undefined}};
        },
    },
});

export const {setFilters, unSetFilter} = slice.actions;

export const statFiltersReducer = slice.reducer;

export const selectStatFilters = (state: RootState) => state.statFilters;
