import React from 'react';
import {DailyStatData} from '../../redux/types';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';

type Props = {
    data?: DailyStatData;
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

const DayStatChart: React.FC<Props> = ({data}) => {
    if (!data || data.length === 0) return null;

    const maxSessions = data.reduce(
        (acc, cur) => (acc > cur.total ? acc : cur.total),
        0,
    );

    const maxLeads = data.reduce(
        (acc, cur) => (acc > cur.achieved_results ? acc : cur.achieved_results),
        0,
    );
    const totalOptions = {
        responsive: true,
        scaleShowVerticalLines: false,
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,
            },
        },
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        maintainAspectRatio: false,
        stacked: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                display: false,
            },
            y_sessions: {
                type: 'linear' as const,
                display: false,
                position: 'left' as const,
                max: maxSessions * 1.1,
                min: 0 - maxSessions,
                gridLines: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    beginAtZero: true,
                    stepSize: 1 as const,
                },
            },
            y_leads: {
                type: 'linear' as const,
                display: false,
                position: 'right' as const,
                grid: {
                    drawOnChartArea: false,
                },
                ticks: {
                    beginAtZero: true,
                    stepSize: 1 as const,
                },
                gridLines: {
                    display: false,
                    drawBorder: false,
                },
                max: maxLeads > 0 ? maxLeads * 2.5 : maxSessions,
                // min: 0 - maxSessions * 2,
            },
        },
    };

    const labels = data.map(dayStat =>
        new Date(dayStat.date).toLocaleDateString(),
    );

    const totalData = {
        labels,
        datasets: [
            {
                label: 'Лиды',
                data: data.map(dayStat => dayStat.achieved_results),
                borderColor: 'rgb(97,100,232)',
                backgroundColor: 'rgba(97,100,232, 0.5)',
                yAxisID: 'y_leads',
                tension: 0.2,
            },
            {
                label: 'Сессии',
                data: data.map(dayStat => dayStat.total),
                borderColor: 'rgb(184,199,219)',
                backgroundColor: 'rgba(184,199,219, 0.5)',
                yAxisID: 'y_sessions',
                tension: 0.2,
            },
        ],
    };

    return <Line options={totalOptions} data={totalData} />;
};

export default DayStatChart;
