import React, {useState} from 'react';
import NewQuestionModal from '../NewQuestionModal';
import {ElementTypes, Quiz} from '../../../../redux/types';
import NewResultModal from '../NewResultModal';
import NewFormModal from '../NewFormModal';
import {nanoid} from '@reduxjs/toolkit';
import NewPageModal from '../NewPageModal';

type Props = {
    quiz: Quiz;
    activeElementId?: string;
    activeElementType?: ElementTypes;
};

type PageModalOpenType = boolean | 'page' | 'modal_window';

const AddElementMenu: React.FC<Props> = React.forwardRef((props, ref) => {
    const [newQuestionModalOpen, setNewQuestionModalOpen] = useState(false);
    const [newResultModalOpen, setNewResultModalOpen] = useState(false);
    const [newFormModalOpen, setNewFormModalOpen] = useState(false);
    const [newPageModalOpenType, setNewPageModalOpenType] =
        useState<PageModalOpenType>(false);

    const questionButtonDisabled =
        props.activeElementType &&
        ['result', 'connection'].includes(props.activeElementType);

    const pageButtonDisabled =
        props.activeElementType &&
        ['result', 'connection'].includes(props.activeElementType);

    const modalWindowButtonDisabled =
        props.activeElementType &&
        ['result', 'connection'].includes(props.activeElementType);

    const formButtonDisabled =
        props.activeElementType &&
        ['form', 'result', 'connection'].includes(props.activeElementType);

    const resultButtonDisabled =
        props.activeElementType &&
        ['result', 'connection'].includes(props.activeElementType);

    const createConnections = (newObjId: string) => {
        if (props.activeElementId === undefined) return [];
        else
            return [
                {
                    id: nanoid(10),
                    source_obj_id: props.activeElementId,
                    target_obj_id: newObjId,
                    quiz_id: props.quiz.id,
                },
            ];
    };

    return (
        <div>
            <NewQuestionModal
                quiz={props.quiz}
                modalOpen={newQuestionModalOpen}
                setModalOpen={setNewQuestionModalOpen}
                createConnections={createConnections}
            />

            <NewResultModal
                quiz={props.quiz}
                modalOpen={newResultModalOpen}
                setModalOpen={setNewResultModalOpen}
                createConnections={createConnections}
            />

            <NewFormModal
                quiz={props.quiz}
                modalOpen={newFormModalOpen}
                setModalOpen={setNewFormModalOpen}
                createConnections={createConnections}
            />

            <NewPageModal
                quiz={props.quiz}
                modalOpen={newPageModalOpenType}
                setModalOpen={setNewPageModalOpenType}
                createConnections={createConnections}
            />

            <div className="border-b border-gray-300 border-dashed pb-2">
                <div className="text-sm">
                    Добавить {props.activeElementType ? 'следующий' : ''}{' '}
                    элемент:
                </div>

                <button
                    className={
                        questionButtonDisabled
                            ? 'btn-xs border-slate-200 hover--border-slate-300 text-gray-400 cursor-not-allowed mr-1 mb-1'
                            : 'btn-xs border-slate-200 hover--border-slate-300 text-indigo-500 cursor-pointer mr-1 mb-1'
                    }
                    disabled={Boolean(questionButtonDisabled)}
                    onClick={e => {
                        e.stopPropagation();
                        setNewQuestionModalOpen(true);
                    }}>
                    <span>вопрос</span>
                </button>

                <button
                    className={
                        formButtonDisabled
                            ? 'btn-xs border-slate-200 hover--border-slate-300 text-gray-400 cursor-not-allowed mr-1 mb-1'
                            : 'btn-xs border-slate-200 hover--border-slate-300 text-indigo-500 cursor-pointer mr-1 mb-1'
                    }
                    disabled={Boolean(formButtonDisabled)}
                    onClick={e => {
                        e.stopPropagation();
                        setNewFormModalOpen(true);
                    }}>
                    <span>форму</span>
                </button>

                <button
                    className={
                        resultButtonDisabled
                            ? 'btn-xs border-slate-200 hover--border-slate-300 text-gray-400 cursor-not-allowed mr-1 mb-1'
                            : 'btn-xs border-slate-200 hover--border-slate-300 text-indigo-500 cursor-pointer mr-1 mb-1'
                    }
                    disabled={Boolean(resultButtonDisabled)}
                    onClick={e => {
                        e.stopPropagation();
                        setNewResultModalOpen(true);
                    }}>
                    <span>результат</span>
                </button>

                <button
                    className={
                        pageButtonDisabled
                            ? 'btn-xs border-slate-200 hover--border-slate-300 text-gray-400 cursor-not-allowed mr-1 mb-1'
                            : 'btn-xs border-slate-200 hover--border-slate-300 text-indigo-500 cursor-pointer mr-1 mb-1'
                    }
                    disabled={Boolean(pageButtonDisabled)}
                    onClick={e => {
                        e.stopPropagation();
                        setNewPageModalOpenType('page');
                    }}>
                    <span>страницу</span>
                </button>

                <button
                    className={
                        pageButtonDisabled
                            ? 'btn-xs border-slate-200 hover--border-slate-300 text-gray-400 cursor-not-allowed mr-1 mb-1'
                            : 'btn-xs border-slate-200 hover--border-slate-300 text-indigo-500 cursor-pointer mr-1 mb-1'
                    }
                    disabled={Boolean(modalWindowButtonDisabled)}
                    onClick={e => {
                        e.stopPropagation();
                        setNewPageModalOpenType('modal_window');
                    }}>
                    <span>модальное окно</span>
                </button>
            </div>
        </div>
    );
});

export default AddElementMenu;
