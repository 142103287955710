import React, {useState} from 'react';
import Graph from './Graph';
import {
    apiValidationErrorResponse,
    Connection,
    ElementTypes,
    Question,
    Quiz,
} from '../../../../redux/types';
import {EventHandler} from 'cytoscape';
import QuestionMenu from './QuestionMenu';
import {toast} from 'react-toastify';
import ConnectionMenu from './ConnectionMenu';
import ResultMenu from './ResultMenu';
import {
    useAddConnectionMutation,
    useGetConnectionsQuery,
    useGetFormsQuery,
    useGetPagesQuery,
    useGetResultsQuery,
    useUpdateQuizMutation,
} from '../../../../redux/services/quizApi';
import FormMenu from './FormMenu';
import AddElementMenu from './AddElementMenu';
import WarningsBlock from './WarningsBlock';
import PageMenu from './PageMenu';

type Props = {
    quiz: Quiz;
    questions: Question[];
    newQuestionModalOpen: boolean;
    setNewQuestionModalOpen: (open: boolean) => void;
};

const GraphBlock: React.FC<Props> = React.forwardRef((props, ref) => {
    const [activeElementType, setActiveElementType] = useState<ElementTypes>();
    const [activeElementId, setActiveElementId] = useState<string>();
    const [needShowCondModal, setNeedShowCondModal] = useState<boolean>(false);
    const [bindingMode, setBindingMode] = useState<boolean>(false);
    const [addConnection, result] = useAddConnectionMutation();
    const {data: results} = useGetResultsQuery(props.quiz.id);
    const {data: forms} = useGetFormsQuery(props.quiz.id);
    const {data: pages} = useGetPagesQuery(props.quiz.id);
    const {data: connections} = useGetConnectionsQuery(props.quiz.id);
    const [updateQuiz] = useUpdateQuizMutation();

    if (
        props.questions === undefined ||
        results === undefined ||
        forms === undefined ||
        connections === undefined ||
        pages === undefined
    )
        return null;

    const saveConnection = async (connection: Connection) => {
        await addConnection(connection)
            .unwrap()
            .then(() => {
                toast.success('Связь успешно сохранена');
                setNeedShowCondModal(true);
                setActiveElementId(connection.id);
                setActiveElementType('connection');
            })
            .catch((response: apiValidationErrorResponse<Question>) => {
                console.error(response);
            });
    };

    const onElementSelect: EventHandler = e => {
        console.log(e.target.id());
        setActiveElementType(e.target.scratch().elementType);
        setActiveElementId(e.target.id());
    };

    const onElementUnselect = () => {
        console.log('onElementUnselect');
        setActiveElementType(undefined);
        setActiveElementId(undefined);
        setBindingMode(false);
    };

    const setElementFirst = async (id: string) => {
        await updateQuiz({...props.quiz, first_element_id: id})
            .unwrap()
            .then(() => {
                onElementUnselect();
                toast.success('Элемент назначен первым');
            })
            .catch((response: apiValidationErrorResponse<Question>) => {
                console.error(response);
            });
    };

    const renderElementMenu = () => {
        switch (activeElementType) {
            case 'question': {
                const question = props.questions?.find(
                    q => q.id === activeElementId,
                );

                return (
                    question && (
                        <QuestionMenu
                            quiz={props.quiz}
                            question={question}
                            setBindingMode={setBindingMode}
                            bindingMode={bindingMode}
                            setElementFirst={setElementFirst}
                        />
                    )
                );
            }

            case 'form': {
                const form = forms?.find(q => q.id === activeElementId);
                return (
                    form && (
                        <FormMenu
                            quiz={props.quiz}
                            setBindingMode={setBindingMode}
                            bindingMode={bindingMode}
                            form={form}
                            setElementFirst={setElementFirst}
                        />
                    )
                );
            }

            case 'page': {
                const page = pages?.find(q => q.id === activeElementId);
                return (
                    page && (
                        <PageMenu
                            quiz={props.quiz}
                            setBindingMode={setBindingMode}
                            bindingMode={bindingMode}
                            page={page}
                            setElementFirst={setElementFirst}
                        />
                    )
                );
            }

            case 'modal_window': {
                const page = pages?.find(q => q.id === activeElementId);
                return (
                    page && (
                        <PageMenu
                            quiz={props.quiz}
                            setBindingMode={setBindingMode}
                            bindingMode={bindingMode}
                            page={page}
                            setElementFirst={setElementFirst}
                        />
                    )
                );
            }

            case 'result': {
                const result = results?.find(q => q.id === activeElementId);
                return result && <ResultMenu result={result} />;
            }

            case 'connection': {
                const connection = connections?.find(
                    q => q.id === activeElementId,
                );
                return (
                    connection && (
                        <ConnectionMenu
                            onElementUnselect={onElementUnselect}
                            connection={connection}
                            questions={props.questions}
                            needShowCondModal={needShowCondModal}
                            setNeedShowCondModal={setNeedShowCondModal}
                        />
                    )
                );
            }

            default:
                return null;
        }
    };

    return (
        <div className="flex flex-row">
            <div className="flex-1">
                <Graph
                    quiz={props.quiz}
                    questions={props.questions}
                    onElementSelect={onElementSelect}
                    onElementUnselect={onElementUnselect}
                    setBindingMode={setBindingMode}
                    bindingMode={bindingMode}
                    activeElementId={activeElementId}
                    saveConnection={saveConnection}
                    results={results}
                    forms={forms}
                    pages={pages}
                    connections={connections}
                />
            </div>

            <div className="flex-none w-64 pl-4">
                <WarningsBlock
                    quiz={props.quiz}
                    questions={props.questions}
                    results={results}
                    connections={connections}
                    forms={forms}
                    pages={pages}
                />
                <AddElementMenu
                    quiz={props.quiz}
                    activeElementId={activeElementId}
                    activeElementType={activeElementType}
                />
                {renderElementMenu()}
            </div>
        </div>
    );
});

export default GraphBlock;
