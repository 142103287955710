import React from 'react';
import {Quiz} from '../../../../redux/types';
import {
    useGetDailyStatDataQuery,
    useGetTotalStatDataQuery,
} from '../../../../redux/services/statApi';
import {Oval} from 'react-loader-spinner';
import DayStatChart from '../../../../components/DayStatChart';
import {FilterAltOutlined, StackedLineChartOutlined} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';

type Props = {
    quiz: Quiz;
};

const StatWidget: React.FC<Props> = ({quiz}) => {
    const navigate = useNavigate();
    const {
        data: totalStat,
        isFetching: isTotalStatFetching,
        isLoading: isTotalStatLoading,
    } = useGetTotalStatDataQuery(quiz.id);

    const {
        data: dailyStat,
        isFetching: isDailyStatFetching,
        isLoading: isDailyStatLoading,
    } = useGetDailyStatDataQuery({
        id: quiz.id,
        filters: {
            dateRange: {
                from: undefined,
                to: undefined,
            },
        },
    });
    return (
        <div className="w-full md:w-[300px] flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200 px-5 py-5 md:ml-5 mb-5">
            <header className="flex justify-between items-start mb-2">
                <h2 className="flex-1 text-lg font-semibold text-gray-800">
                    Статистика
                </h2>
                <div className="">
                    <button
                        className="text-gray-400 hover:text-gray-500 rounded-full mr-1"
                        title="Статистика по дням"
                        onClick={() => navigate('/dailyStat/' + quiz.id)}>
                        <StackedLineChartOutlined />
                    </button>
                    <button
                        className="text-gray-400 hover:text-gray-500 rounded-full"
                        title="Воронка прохождений"
                        onClick={() => navigate('/funnel/' + quiz.id)}>
                        <FilterAltOutlined />
                    </button>
                </div>
            </header>
            {isTotalStatFetching || isTotalStatLoading || !totalStat ? (
                <Oval width={30} color={'grey'} secondaryColor={'lightgray'} />
            ) : (
                <div className="flex flex-row flex-nowrap w-full">
                    <div className="pr-1">
                        <div className="text-xs font-semibold text-gray-400 uppercase mt-3 mb-1">
                            Сессии
                        </div>
                        <div className="text-gray-800 text-3xl font-semibold">
                            {totalStat.total}
                        </div>
                    </div>
                    <div className="px-5">
                        <div className="text-xs font-semibold text-gray-400 uppercase mt-3 mb-1">
                            Лиды
                        </div>
                        <div className="flex items-start text-gray-800 text-3xl font-semibold">
                            {totalStat.achieved_results}
                        </div>
                    </div>
                    <div className="pl-1">
                        <div className="text-xs font-semibold text-gray-400 uppercase mt-3 mb-1">
                            Конверсия
                        </div>
                        <div className="flex items-start text-gray-800 text-3xl font-semibold">
                            {totalStat.total > 0
                                ? (
                                      (totalStat.achieved_results /
                                          totalStat.total) *
                                      100
                                  ).toFixed(2)
                                : 0}
                            %
                        </div>
                    </div>
                </div>
            )}
            {isDailyStatFetching || isDailyStatLoading ? (
                <Oval width={30} color={'grey'} secondaryColor={'lightgray'} />
            ) : (
                dailyStat &&
                dailyStat.length > 1 && (
                    <div className="max-h-[100px]">
                        <DayStatChart data={dailyStat} />
                    </div>
                )
            )}
        </div>
    );
};
export default StatWidget;
