import React, {useEffect, useState} from 'react';
import Breadcrumbs from '../../components/Elements/Breadcrumbs';
import {LeadReportFilters} from '../../redux/types';
import Filter from './Filter';
import Table from './Table';

function ReportsPage() {
    const [filters, setFilters] = useState<LeadReportFilters>({
        dateRange: {from: undefined, to: undefined},
    });

    return (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                    <Breadcrumbs breadcrumbs={[{title: 'Отчеты по лидам'}]} />
                </div>
                <div className="text-xs">
                    Лидами считаются события отправки заполненной формы.
                </div>
            </div>

            <Filter onSubmit={setFilters} />
            {filters.dateRange.from && filters.dateRange.to ? (
                <Table filters={filters} />
            ) : (
                <div className="mt-3">
                    Выберите даты для формирования отчета.
                </div>
            )}
        </div>
    );
}

export default ReportsPage;
